'use client';

import styled from '@emotion/styled';
import { useTokens } from '@mezzoforte/forge';

interface StyleProps {
  displayDuration: number;
  numItems: number;
}

const { colors, breakpoints, space } = useTokens.huutokaupat;

export const BannerItem = styled.span<{ index: number; animate: boolean }>`
  ${({ index, animate }) =>
    animate &&
    `
        grid-area: active;
        opacity: 0;
        animation: toggleDisplayed var(--display-duration) infinite,
          keepHidden calc(var(--display-duration) * var(--num-items)) infinite;
        animation-delay: calc(var(--display-duration) * ${index});
      `}

  @media screen and (min-width: ${breakpoints.lg}) {
    :not(:first-of-type):before {
      content: ' | ';
      color: ${colors.subdued};
      padding: 0 0.5rem;
    }
  }
`;

export const BannerWrapper = styled.aside<StyleProps>`
  --display-duration: ${props => props.displayDuration}ms;
  --num-items: ${props => props.numItems};
  // hex from brandSecondary with opacity .96
  background-color: #20371c;
  color: ${colors.textInvert};
  padding: ${space[2]} 0;

  a {
    color: inherit;
    text-decoration: underline;
  }

  div {
    display: grid;
    grid-template-areas: 'active';
  }

  @media screen and (min-width: ${breakpoints.lg}) {
    span {
      opacity: 1;
      animation: none;
    }

    div {
      display: block;
    }
  }

  @keyframes toggleDisplayed {
    0%,
    100% {
      opacity: 0;
    }
    5%,
    95% {
      opacity: 1;
    }
  }

  @keyframes keepHidden {
    0% {
      visibility: visible;
    }
    ${({ numItems }) => 100 / numItems}%,
    100% {
      visibility: hidden;
    }
  }
`;
