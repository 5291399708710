'use client';

import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, useTokens } from '@mezzoforte/forge';

const { fontSizes, fontWeights, lineHeights, breakpoints } = useTokens.huutokaupat;

const Container = styled(Box)`
  font-family: var(--heading-font-family);
  font-size: ${fontSizes.button};
  font-weight: ${fontWeights.button};
  line-height: ${lineHeights.button};
  padding-bottom: env(safe-area-inset-bottom);

  /* Mobile styles */
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(--chakra-zIndices-modal);

  &:has([aria-expanded='true']):after {
    content: '';
    position: fixed;
    height: calc(100vh + 80px);
    top: 74px; // SiteHeader.NAV_HEIGHT
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;

    @media screen and (max-width: ${breakpoints.lg}) {
      top: 0;
    }
  }
`;

export default function NavContainer({ children }: React.PropsWithChildren) {
  return (
    <Container
      as="nav"
      backgroundColor="brandSecondary"
      position={{ base: 'fixed', lg: 'static' }}
      data-test="main-navigation"
    >
      {children}
      <Global styles={mobileBodyMargin} />
    </Container>
  );
}

const mobileBodyMargin = css`
  @media screen and (max-width: ${breakpoints.lg}) {
    body {
      margin-bottom: 60px;
    }
  }
`;
