import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { client } from 'util/apiClient';
import type { Session } from 'types/Session';
import type { LoginAPIRequest } from 'types/ApiRequest';
import type {
  EntryUserInfoAPIResponse,
  LoginAPIResponse,
  LoginFailureAPIResponse,
  LoginPromptTwoFactorAPIResponse,
  LoginSuccessAPIResponse,
} from 'types/ApiResponse';

const fetchCurrentUser = async (): Promise<Session> =>
  client.get<Session>('/api/users/current-user').then<Session>(response => {
    if (!response.data.isAuthenticated) {
      localStorage.removeItem('sessiontoken');
    }

    return response.data;
  });

const fetchEntryForUser = async (entryId: number): Promise<EntryUserInfoAPIResponse> =>
  client.get<EntryUserInfoAPIResponse>(`/api/net-auctions/${entryId}/user-info`).then(response => response.data);

const submitLogin = (payload: LoginAPIRequest) =>
  client.post<LoginAPIResponse>('/login', payload).then(response => {
    if (!response?.data.success) {
      // Failed login attempt returns 200 OK, we need to manually reject the promise
      // when payload has { success: false }.
      return Promise.reject(response.data);
    }
    return Promise.resolve(response.data);
  });

export const useSession = () => {
  const queryClient = useQueryClient();
  const userQuery = useQuery<Session>({ queryKey: ['currentUser'], queryFn: fetchCurrentUser, staleTime: 1000 * 60 });

  const useEntryInfo = (entryId: number) =>
    useQuery<EntryUserInfoAPIResponse>({
      queryKey: ['currentUser', 'entries', entryId],
      queryFn: () => fetchEntryForUser(entryId),
      staleTime: 1000 * 60,
    });

  return {
    currentUser: userQuery,
    entryInfo: useEntryInfo,
    login: useMutation<
      LoginSuccessAPIResponse | LoginPromptTwoFactorAPIResponse,
      LoginFailureAPIResponse,
      LoginAPIRequest
    >({
      mutationFn: submitLogin,
      onSuccess: response => {
        if (!response.promptTwoFactor) {
          // We have successfully logged in. Refetch current user
          queryClient.refetchQueries({ queryKey: ['currentUser'], type: 'active' });
          // Set user's info in a base64 encoded json string to localStorage.
          // Required for old frontend to handle auth, **should be removed after no longer necessary**
          // as this exposes user's personal information in an insecure way.
          // NEVER TRUST THE CONTENTS OF THIS LOCALSTORAGE ITEM!!
          localStorage.setItem('sessiontoken', response.token);
        }
      },
    }),
    logout: useMutation({
      mutationFn: () => client.get('/logout'),
      onSuccess: () => {
        userQuery.refetch();
        localStorage.removeItem('sessiontoken');
      },
    }),
  };
};
