import { getRepositoryName, createClient as createPrismicClient } from '@prismicio/client';
import { type LinkResolverFunction } from '@prismicio/helpers';
import { enableAutoPreviews, type CreateClientConfig } from '@prismicio/next';
import sm from './slicemachine.config.json';

/**
 * The project's Prismic repository name.
 */
export const repositoryName = getRepositoryName(sm.apiEndpoint);

/**
 * The project's Prismic Link Resolver. This function determines the URL for a given Prismic document.
 *
 * @type {LinkResolverFunction}
 */
// Update the Link Resolver to match your project's route structure
export const linkResolver: LinkResolverFunction = doc => {
  switch (doc.type) {
    case 'front_page':
    case 'settings':
      return '/';
    case 'page':
      return `/${doc.slug}`;
    case 'dynamic-page':
      return `/${doc.uid}`;
    default:
      return '';
  }
};

/**
 * Creates a Prismic client for the project's repository. The client is used to
 * query content from the Prismic API.
 *
 * @param config {prismicNext.CreateClientConfig} - Configuration for the Prismic client.
 */
export const createClient = (config: CreateClientConfig = {}) => {
  const client = createPrismicClient(sm.apiEndpoint, {
    accessToken: process.env.PRISMIC_ACCESS_TOKEN,
    fetchOptions: { next: { revalidate: 300 } }, // TODO: On-demand revalidation in production env
    routes: [
      {
        type: 'front_page',
        path: '/',
      },
      {
        type: 'dynamic-page',
        path: '/:uid',
      },
    ],
    ...config,
  });

  enableAutoPreviews({
    client,
  });

  return client;
};
