'use client';

import { Content, SliceZone } from '@prismicio/client';
import { PrismicLink, PrismicRichText, PrismicText, SliceComponentProps } from '@prismicio/react';
import { GridItem, GridItemProps } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Box, Flex, useTokens } from '@mezzoforte/forge';
import { ArrowRight } from '@mezzoforte/forge-icons';

import { RegistrationLinkBlockSlice } from 'prismicio-types';

const { colors, radii, borders } = useTokens.huutokaupat;

/**
 * Props for `RegistrationLinkBlock`.
 */
export type RegistrationLinkBlockProps = SliceComponentProps<Content.RegistrationLinkBlockSlice>['slice'];

interface RegistrationLinkBlocksProps extends GridItemProps {
  blocks: SliceZone<RegistrationLinkBlockSlice>;
}

const MobileRegistrationLinkBlocks = ({ blocks, ...gridItemProps }: RegistrationLinkBlocksProps) => {
  return (
    <GridItem {...gridItemProps}>
      {blocks.map((slice, index) => (
        <RegistrationLinkBlock key={index} {...slice} />
      ))}
    </GridItem>
  );
};

export const RegistrationLinkBlocks = ({ blocks }: Pick<RegistrationLinkBlocksProps, 'blocks'>) => {
  return (
    <Box padding={2}>
      {blocks.map((slice, index) => (
        <RegistrationLinkBlock key={index} {...slice} />
      ))}
    </Box>
  );
};

/**
 * Component for "RegistrationLinkBlock" Slices.
 */
const RegistrationLinkBlock = (slice: RegistrationLinkBlockProps) => {
  return (
    <section data-slice-type={slice.slice_type} data-slice-variation={slice.variation}>
      {slice.items.map((item, index) => (
        <Box key={index} paddingLeft={{ base: 3, md: 5 }} paddingRight={{ base: 3, md: 0 }}>
          <Box
            padding={4}
            border={borders.input}
            borderColor={colors.lineBorder}
            borderRadius={radii.box}
            minHeight={20}
          >
            <Flex
              direction="column"
              justifyContent="space-between"
              css={{
                p: {
                  marginBlockStart: 0,
                  marginBlockEnd: '0.5em',
                },
              }}
            >
              <PrismicRichText field={item.text} />
              <PrismicLink field={item.cta_link}>
                <CallToActionText>
                  <PrismicText field={item.cta_text} /> <ArrowRight size={16} />
                </CallToActionText>
              </PrismicLink>
            </Flex>
          </Box>
        </Box>
      ))}
    </section>
  );
};

export default MobileRegistrationLinkBlocks;

const CallToActionText = styled.span`
  align-items: center;
  color: ${colors.brand};
  display: inline-flex;
  gap: 0.5rem;

  &:hover {
    text-decoration: none;
  }
`;
