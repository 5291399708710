import axios from 'axios';

const NEXT_PUBLIC_BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

export const client = axios.create({
  baseURL: NEXT_PUBLIC_BASE_URL,
  withCredentials: true,
  xsrfCookieName: 'csrf-token',
  xsrfHeaderName: 'X-Csrf-Token',
  headers: {
    'Cache-Control': 'no-cache',
  },
});

export function apiFetch(url: string, method = 'GET'): Promise<Response> {
  return fetch(`${process.env.NEXT_PUBLIC_BASE_URL}${url}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      Accept: 'application/json',
    },
  });
}
